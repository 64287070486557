import Image, { StaticImageData } from "next/image";
import Script from "next/script";

interface GoogleItemScopeProps {
  avgRating: number;
  totalRatings: number;
  productName: string;
  productDescription: string;
  productImages: StaticImageData[];
}

/**
 * Google structured data script that should be placed in a product page's
 * <head> or <body>. This will help Google display "rich snippet" data in
 * search results.
 *
 * @component
 * @param {GoogleItemScopeProps} props - The props for the component.
 * @param {number} props.avgRating - The average rating of the product.
 * @param {number} props.totalRatings - The total number of ratings for the product.
 * @param {string} props.productName - The name of the product.
 * @param {string} props.productDescription - The description of the product.
 * @param {string} props.productImages - A list of product images to include.
 * Ideally multiple images with different aspect ratios.
 */
const GoogleProductMetadata: React.FC<GoogleItemScopeProps> = ({
  avgRating,
  totalRatings,
  productName,
  productDescription,
  productImages,
}) => {
  return (
    <script
      type="application/ld+json"
      key="product-jsonld"
      dangerouslySetInnerHTML={{
        __html: `{
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "${productName}",
          "image": [
            ${productImages
              .map((image) => {
                return `"https://www.alterme.com${image.src}"`;
              })
              .join(", ")}
          ],
          "description": "${productDescription}",
          "brand": {
            "@type": "Brand",
            "name": "Alter"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "${avgRating}",
            "reviewCount": "${totalRatings}"
          }
        }`,
      }}
    />
  );
};

export default GoogleProductMetadata;
